import { IonAlert, IonButton, IonInput, IonItem, IonList } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { FieldError, NormalText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { validateForm } from '../../lib/utils';
import { resetPassword, setModal } from '../../store/actions';
import './index.css';

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			formErrors: {},
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleResetPassword = this.handleResetPassword.bind(this);
		this.formConfig = {
			email: { type: 'email', required: true },
		};
	}

	handleResetPassword() {
		let formErrors = validateForm(this.formConfig, this.state);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const { email } = this.state;
			this.props.dispatch(resetPassword(email));
		}
	}

	handleInput = (key, val) => this.setState({ [key]: val });

	returnToLogin = (history) => history.goBack();

	render() {
		const { __, isResetPasswordModalOpen } = this.props;
		const { email, formErrors } = this.state;
		return (
			<>
				<Layout headerTitle={__('Forgot password')} color="transparent">
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title>{__('Forgot password')}</Title>
							<NormalText className="block">{__("We all do it, don't worry. Check your email - we'll send you a reminder.")}</NormalText>
							<Spacer size={2} />
							<IonList>
								<IonItem lines="none" className="input-field-wrapper">
									<IonInput
										placeholder={__('Enter your email address')}
										onIonChange={(e) => this.handleInput('email', e.target.value)}
										clearInput
										required={true}
										type="email"
										pattern="email"
										inputmode="email"
										value={email}
									/>
								</IonItem>
								{formErrors.email ? <FieldError className="field-error" value={__(formErrors.email)} /> : null}
							</IonList>
							<Spacer size={2} />
							<IonButton expand="block" color="primary" className="uppercase okx-font-secondary" onClick={() => this.handleResetPassword()}>
								{__('Reset password')}
							</IonButton>
						</div>
					</div>
				</Layout>
				<IonAlert
					isOpen={isResetPasswordModalOpen}
					onDidDismiss={() => this.props.dispatch(setModal('isResetPasswordModalOpen', false))}
					header={__('Bingo.')}
					message={__('Please check your email')}
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isResetPasswordModalOpen', false))),
						},
					]}
				/>
			</>
		);
	}
}

const stateToProps = (state) => {
	const { auth, isResetPasswordModalOpen } = state.profile;
	return {
		auth,
		isResetPasswordModalOpen,
	};
};

export default connect(stateToProps)(withTranslation(ResetPassword));
