import { IonButton, IonCheckbox, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepCopy, forwardTo, validateForm } from '../../lib/utils';
import { setGuestUser, updateProfile } from '../../store/actions';
import './index.css';
import MobileInput from '../../components/mobileInput';

const { getMobile, setMobile } = Basket;

class ContactDetails extends Component {
	constructor(props) {
		super(props);
		const parsedProps = this.props?.history?.location?.state || {};
		this.state = {
			first_name: parsedProps?.first_name || this.props.profile.first_name || '',
			last_name: parsedProps?.last_name || this.props.profile.last_name || '',
			mobile: getMobile() || this.props.profile.mobile || '',
			formErrors: {},
			guest_first_name: parsedProps?.first_name || this.props.guestUserData.guest_first_name || '',
			guest_email: parsedProps?.email || this.props.guestUserData.guest_email || '',
			guest_accepted_terms_and_conditions: this.props.guestUserData.guest_accepted_terms_and_conditions || false,
			guest_mobile: getMobile() || this.props.guestUserData.guest_mobile || '',
			guestFormErrors: {},
			guest_mobile_value: '',
			guest_mobile_code: '',
			mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
			mobile_value: this.props.profile.mobile_value ? this.props.profile.mobile_value : this.props.profile.mobile == this.props.profile.mobile_code ? '' : this.props.profile.mobile,
		};
		this.guestValidationConfig = {
			guest_first_name: { type: 'first_name', required: true },
			guest_mobile: { type: 'tel', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
			...(getConfig().flags.hasMobileCountryCode && {
				guest_mobile_code: { type: 'text', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
			}),
			...(getConfig().flags.hasMobileCountryCode && {
				guest_mobile_value: { type: 'number', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
			}),
			guest_email: { type: 'email', required: Basket.getOrderType() !== 'Table' },
			guest_accepted_terms_and_conditions: { type: 'toggle', toggle: true },
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.formConfig = {
			first_name: {
				type: 'first_name',
				required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'first_name').length > 0 : true,
			},
			// mobile: {
			// 	type: 'tel',
			// 	required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true,
			// },
			// ...(getConfig().flags.hasMobileCountryCode && {
			// 	mobile_code: {
			// 		type: 'text',
			// 		required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true,
			// 	},
			// }),
			// ...(getConfig().flags.hasMobileCountryCode && {
			// 	mobile_value: {
			// 		type: 'text',
			// 		required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true,
			// 	},
			// }),
		};
	}

	componentDidMount() {
		if (this.state.first_name !== '' && this.state.mobile !== '' && !getConfig().flags.hasYocoPayment) {
			forwardTo('/checkout', { skipContactDetails: true });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.first_name !== prevProps.profile.first_name) {
			this.setState({ first_name: this.props.profile.first_name });
		}
		if (this.props.profile.last_name !== prevProps.profile.last_name) {
			this.setState({ last_name: this.props.profile.last_name });
		}
		if (this.props.profile.mobile !== prevProps.profile.mobile) {
			this.setState({
				mobile: this.props.profile.mobile ? this.props.profile.mobile : getMobile(),
			});
		}
	}

	handleInput = (key, val) => {
		if (key === 'mobile' || key === 'guest_mobile') {
			setMobile(val);
		}
		this.setState({ [key]: val });
	};

	handleSave() {
		let formErrors = validateForm(this.formConfig, this.state);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const { first_name, last_name, mobile } = this.state;
			const profile = {
				first_name: first_name,
				last_name: last_name,
				// mobile: mobile ? mobile : getMobile(),
			};
			if (getConfig().flags.hasYocoPayment) {
				if (mobile) {
					this.props.dispatch(updateProfile(profile, true));
				}
				Basket.createYocoOrder('yoco');
			} else {
				if (mobile) {
					this.props.dispatch(updateProfile(profile, true));
					if (Basket.getTotal() === 0) {
						Basket.createOrder();
					} else {
						forwardTo('/checkout');
					}
				} else {
					forwardTo('/checkout');
				}
			}
		}
	}
	handleGuestSave = () => {
		const { dispatch } = this.props;
		let guestFormErrors;
		if (Basket.getOrderType() == 'Table' && this.state.guest_email == '') {
			const guestValidationConfigCopy = deepCopy(this.guestValidationConfig);
			delete guestValidationConfigCopy.guest_email;
			guestFormErrors = validateForm(guestValidationConfigCopy, this.state);
		} else {
			guestFormErrors = validateForm(this.guestValidationConfig, this.state);
		}
		this.setState({ guestFormErrors });
		if (Object.keys(guestFormErrors).length === 0) {
			const { guest_mobile_value, guest_mobile_code, guest_email, guest_first_name, guest_mobile, guest_accepted_terms_and_conditions } = this.state;
			const guestUserData = {
				guest_email,
				guest_first_name,
				guest_mobile,
				guest_mobile_code,
				guest_mobile_value,
				guest_accepted_terms_and_conditions,
			};
			dispatch(setGuestUser(guestUserData));
			forwardTo('/create-account', { referrer: this?.props?.location?.state?.referrer });
		}
	};
	setGuestMobileCode = (guest_mobile_code) => {
		this.setState({ guest_mobile_code, guest_mobile: `${guest_mobile_code}${this.state.guest_mobile_value}` });
		Basket.setMobile(`${guest_mobile_code}${this.state.guest_mobile_value}`);
	};
	setGuestMobileValue = (guest_mobile_value) => {
		this.setState({ guest_mobile_value, guest_mobile: `${this.state.guest_mobile_code}${guest_mobile_value}` });
		Basket.setMobile(`${this.state.guest_mobile_code}${guest_mobile_value}`);
	};
	setMobileCode = (mobile_code) => {
		this.setState({ mobile_code, mobile: `${mobile_code}${this.state.mobile_value}` });
		Basket.setMobile(`${mobile_code}${this.state.mobile_value}`);
	};
	setMobileValue = (mobile_value) => {
		this.setState({ mobile_value, mobile: `${this.state.mobile_code}${mobile_value}` });
		Basket.setMobile(`${this.state.mobile_code}${mobile_value}`);
	};
	render() {
		const { __ } = this.props;
		const {
			mobile_code,
			mobile_value,
			guest_mobile_value,
			guest_mobile_code,
			first_name,
			last_name,
			mobile,
			formErrors,
			guest_first_name,
			guest_mobile,
			guest_email,
			guest_accepted_terms_and_conditions,
			guestFormErrors,
		} = this.state;

		const tableRequiredFields = getConfig().general.tableRequiredFields || [];
		return (
			<Loading transparent>
				<Layout color="transparent" headerWithTitle={true} title="Contact Details">
					<div className="absolute-content scrollable-y ">
						<div className=" checkout">
							<Title className="web-only">{__('Contact Details')}</Title>
							<NormalText>{__('To complete your order, please provide a few details in case we need to contact you')}</NormalText>
							<Spacer size={2} />
							{this.props.auth && this.props.auth.loggedIn ? (
								<>
									{Basket.getOrderType() !== 'Table' ? (
										<IonList>
											<div className="input-field-container">
												<NormalText>{__('First name')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														onIonChange={(e) => this.handleInput('first_name', e.target.value)}
														clearInput
														required={true}
														type="text"
														pattern="text"
														inputmode="text"
														value={first_name}
													></IonInput>
													{formErrors.first_name ? <FieldError className="field-error" value={__(formErrors.first_name)} /> : null}
												</IonItem>
											</div>
											<div className="input-field-container">
												<NormalText>{__('Last name')}</NormalText>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														onIonChange={(e) => this.handleInput('last_name', e.target.value)}
														clearInput
														required={true}
														type="text"
														pattern="text"
														inputmode="text"
														value={last_name}
													></IonInput>
												</IonItem>
											</div>
											{/* {getConfig().flags.hasMobileCountryCode ? (
												<div className="input-field-container">
													<NormalText>{__('Contact Number')}</NormalText>
													<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
												</div>
											) : (
												<div className="input-field-container">
													<NormalText>{__('Mobile number')}</NormalText>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															onIonChange={(e) => this.handleInput('mobile', e.target.value)}
															clearInput
															required={true}
															type="tel"
															pattern="tel"
															inputmode="tel"
															onKeyPress={(e) => {
																const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
																let regex = new RegExp(reqexFormat);
																if (regex.test(e.key)) {
																	return true;
																}
																e.preventDefault();
																return false;
															}}
															value={mobile}
														></IonInput>
													</IonItem>
												</div>
											)}
											{formErrors.mobile ? <FieldError className="field-error" value={__(formErrors.mobile)} /> : null} */}
										</IonList>
									) : (
										<IonList>
											{tableRequiredFields.filter((el) => el === 'first_name').length > 0 && (
												<div className="input-field-container">
													<NormalText>{__('First name')}</NormalText>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															onIonChange={(e) => this.handleInput('first_name', e.target.value)}
															clearInput
															required={true}
															type="text"
															pattern="text"
															inputmode="text"
															value={first_name}
														></IonInput>
													</IonItem>
													{formErrors.first_name ? <FieldError className="field-error" value={__(formErrors.first_name)} /> : null}
												</div>
											)}
											{tableRequiredFields.filter((el) => el === 'last_name').length > 0 && (
												<div className="input-field-container">
													<NormalText>{__('Last name')}</NormalText>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															onIonChange={(e) => this.handleInput('last_name', e.target.value)}
															clearInput
															required={true}
															type="text"
															pattern="text"
															inputmode="text"
															value={last_name}
														></IonInput>
													</IonItem>
												</div>
											)}
											{/* {tableRequiredFields.filter((el) => el === 'mobile').length > 0 && (
												<>
													{getConfig().flags.hasMobileCountryCode ? (
														<div className="input-field-container">
															<NormalText>{__('Contact Number')}</NormalText>
															<MobileInput
																mobile_code={mobile_code}
																mobile_value={mobile_value}
																setMobileCode={this.setMobileCode}
																setMobileValue={this.setMobileValue}
															/>
														</div>
													) : (
														<div className="input-field-container">
															<NormalText>{__('Mobile number')}</NormalText>
															<IonItem lines="none" className="input-field-wrapper">
																<IonInput
																	onIonChange={(e) => this.handleInput('mobile', e.target.value)}
																	clearInput
																	required={true}
																	type="tel"
																	pattern="tel"
																	inputmode="tel"
																	onKeyPress={(e) => {
																		const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
																		let regex = new RegExp(reqexFormat);
																		if (regex.test(e.key)) {
																			return true;
																		}
																		e.preventDefault();
																		return false;
																	}}
																	value={mobile}
																></IonInput>
																{formErrors.mobile ? <FieldError className="field-error" value={__(formErrors.mobile)} /> : null}
															</IonItem>
														</div>
													)}
												</>
											)} */}
										</IonList>
									)}
								</>
							) : (
								<>
									<IonList>
										<div className="input-field-container">
											<NormalText>{__('First Name')}</NormalText>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													onIonChange={(e) => this.handleInput('guest_first_name', e.target.value)}
													onIonBlur={(e) => {
														const autoInput = document.querySelector('input[type="text"]:-webkit-autofill');
														if (autoInput) {
															this.handleInput('guest_first_name', autoInput.value);
														}
														this.handleInput('guest_first_name', e.target.value);
													}}
													clearInput
													required={true}
													type="text"
													pattern="text"
													inputmode="text"
													value={guest_first_name}
												></IonInput>
											</IonItem>
										</div>
										{guestFormErrors.guest_first_name ? <FieldError className="field-error" value={__(guestFormErrors.guest_first_name)} /> : null}
										<div className={`input-field-container`}>
											{Basket.getOrderType() == 'Table' ? (
												<NormalText>{__('If you wish to Recive your receipt via email, enter your email address below')}</NormalText>
											) : (
												<NormalText>{__('Email')}</NormalText>
											)}
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													onIonChange={(e) => this.handleInput('guest_email', e.target.value.trim())}
													onIonBlur={(e) => {
														const autoInput = document.querySelector('input[type="email"]:-webkit-autofill');
														if (autoInput) {
															this.handleInput('guest_email', autoInput.value);
														}
														this.handleInput('guest_email', e.target.value);
													}}
													clearInput
													required={Basket.getOrderType() !== 'Table'}
													type="email"
													pattern="email"
													inputmode="email"
													value={guest_email}
												></IonInput>
											</IonItem>
										</div>
										{guestFormErrors.guest_email ? <FieldError className="field-error" value={__(guestFormErrors.guest_email)} /> : null}
										{(Basket.getOrderType() !== 'Table' || (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0) && (
											<>
												{getConfig().flags.hasMobileCountryCode ? (
													<div className="input-field-container">
														<MobileInput
															mobile_code={guest_mobile_code}
															mobile_value={guest_mobile_value}
															setMobileCode={this.setGuestMobileCode}
															setMobileValue={this.setGuestMobileValue}
														/>
													</div>
												) : (
													<div className="input-field-container">
														<NormalText>{__('Mobile number')}</NormalText>
														<IonItem lines="none" className="input-field-wrapper">
															<IonInput
																onIonChange={(e) => this.handleInput('guest_mobile', e.target.value)}
																clearInput
																required={true}
																type="tel"
																pattern="tel"
																inputmode="tel"
																onKeyPress={(e) => {
																	const reqexFormat = guest_mobile ? '^[0-9]+$' : '^[+0-9]+$';
																	let regex = new RegExp(reqexFormat);
																	if (regex.test(e.key)) {
																		return true;
																	}
																	e.preventDefault();
																	return false;
																}}
																value={guest_mobile}
															></IonInput>
														</IonItem>
													</div>
												)}
												{guestFormErrors.guest_mobile || guestFormErrors.guest_mobile_value ? (
													<FieldError className="field-error" value={__(guestFormErrors.guest_mobile || guestFormErrors.guest_mobile_value)} />
												) : null}
											</>
										)}
										<Spacer size={1} />

										<div lines="none">
											<div tabIndex="-1"></div>
											<div className="toggle">
												<IonLabel>
													<StrongText>{__('Terms & conditions')}</StrongText>
													<div className="register-terms-wrapper">
														<IonCheckbox
															color="primary"
															checked={guest_accepted_terms_and_conditions}
															onIonChange={(e) => this.handleInput('guest_accepted_terms_and_conditions', e.detail.checked)}
														/>
														<IonLabel className="ion-text-wrap">
															<NormalText color="primary">
																{__('By signing up you agree to') + ' '}{' '}
																<span className="pointer underlined primary-color" onClick={() => forwardTo('/terms')}>
																	{__('our terms and conditions')}
																</span>{' '}
																{__('and')}{' '}
																<span className="primary-color  pointer underlined" onClick={() => forwardTo('/privacy')}>
																	{__('privacy policy')}
																</span>
															</NormalText>
														</IonLabel>
													</div>
												</IonLabel>
												<FieldError className="field-error" value={__(guestFormErrors.guest_accepted_terms_and_conditions)} />{' '}
											</div>
										</div>
									</IonList>
								</>
							)}
						</div>
						<div className="flex-min">
							<IonButton onClick={this.props.auth && this.props.auth.loggedIn ? this.handleSave : this.handleGuestSave} expand="block" className="uppercase okx-font-secondary">
								{__('Checkout')}
							</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, profile, guestUserData, registerFormData } = state.profile;
	const { cards } = state.orders;
	return {
		auth,
		profile,
		cards,
		guestUserData: guestUserData || {
			guest_first_name: '',
			guest_email: '',
			guest_accepted_terms_and_conditions: false,
			guest_mobile: '',
		},
		registerFormData,
	};
};

export default connect(stateToProps)(withTranslation(ContactDetails));
